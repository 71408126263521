import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      ru: {
        translations: {
          block0:"В мире есть самые современные технологии…",
          block0_1:"эффективные разработки...",
          block0_2:"и есть надежные партнеры",
          
          block0_3:"Многие из Вас сегодня впервые узнают о нашей компании, а те, кто сотрудничает с нами, возможно, еще раз убедятся в правильности сделанного выбора. За время своего существования, мы добились определенных успехов и достижений, расширили рынок предложения своих услуг. Границы нашего сотрудничества сейчас охватывают круг как отечественных, так и зарубежных партнеров. Инициатива, увлеченность, квалифицированный персонал, преданность компании и забота об интересах заказчика – вот ключ к достижению поставленных целей. Приглашаем Вас ознакомиться с нашим опытом и производственными ресурсами и выражаем уверенность, что «Инженерная фирма MG Engineering» может быть полезна в успешной реализации запланированных Вами задач. Мы открыты для сотрудничества со всеми заинтересованными компаниями и будем оказывать всемерную поддержку в построении и укреплении взаимовыгодных партнерских отношений.",
          block0_4:"С наилучшими пожеланиями успехов в бизнесе,",
          block0_5:"Коллектив «Инженерной фирмы MG Engineering»",
          block0_6:"",
          block0_7:"",
          block0_8:"",
          block0_9:"",
          // block0:"",
          // block0:"",
          // block0:"",
          // block0:"",
          // block0:"",
          // block0:"",
//Блок О КОМПАНИИ на русском
          block1:"ТОО «Инженерная фирма MG Engineering» основана в августе 2002 г. по инициативе группы специалистов, долгое время проработавших в нефтегазовой и строительной отраслях. До декабря 2013 г. компания известна под названием «Мунай Графика», но после завершенной реструктуризации переименована и продолжает работу под новым названием, отражающим основное направление профессиональной деятельности.",
          block1_1:"«Инженерная фирма MG Engineering» специализируется в области инженерно-технической разработки и проектирования, материально-технического обеспечения и комплектации, управления строительным производством нефтегазовых сооружений и предприятий.",
          block1_2:"Основная цель – качественное выполнение (в максимально сжатые и гарантированные сроки) комплекса инженерных услуг, обеспечивающее и удовлетворяющее все требования заказчика.",

          block2:"Для достижения поставленных целей ТОО «Инженерная фирма MG Engineering» обладает сформировавшимся потенциалом. В своей работе мы используем все доступные возможности и инструменты, включая:",
          
          block2_1:"Применение новых технологий;",
          block2_2:"Действующая, сертифицированная система управления качеством;",
          block2_3:"Постоянное повышение профессионального уровня и квалификации сотрудников;",
          block2_4:"Инновационные подходы и современные технические решения;",
          block2_5:"Эффективное управление проектированием;",
          block2_6:"Использование практического опыта предшествующих реализованных проектов.",

          block3:"Работая в условиях рынка и осознавая необходимость повышения конкурентоспособности, мы уделяем большое внимание постоянному улучшению качества предоставляемых услуг. Для максимального сокращения сроков при соблюдении стандартов качества ТОО «Инженерная фирма MG Engineering» использует в полном масштабе современные технологии в проектировании.",
          block3_1:"Реализовав множество серьезных проектов, мы значительно расширили рынок предложения своих услуг, заняв в нем определенную нишу. Границы нашего сотрудничества сейчас охватывают круг как отечественных, так и зарубежных партнеров.",
          
          block3_2:"Критерии успешной деятельности:",
          block3_3:"Заинтересованность заказчика в наших услугах;",
          block3_4:"Четкое формулирование перечня предоставляемых услуг;",
          block3_5:"Полный объем выполняемых работ от анализа и проектирования до сдачи объекта «под ключ»;",
          block3_6:"Тщательное планирование и контроль выполнения подрядных работ и услуг;",
          block3_7:"Разбивка проекта по этапам;",
          block3_8:"Тесное взаимодействие в ходе работ с партнером-заказчиком;",
          block3_9:"Реальность и оправданность ожиданий заказчика;",
          block3_10:"Четкое понимание поставленных задач и целей каждым членом коллектива;",
          block3_11:"Трудолюбивый, квалифицированный, ориентированный на наилучшее выполнение работы персонал.",
 //Закончился  Блок О КОМПАНИИ на русском      
          
//Управление 
          block4:"О КОМПАНИИ",
          block4_1:"Наша компания на постоянной основе сотрудничает с проектными институтами России, Украины, Латвии, Канады и Германии.",
          block4_2:"В период с 2002 г. компания участвовала в реализации более 175 проектов строительства нефтегазовых предприятий, объектов и сооружений различной сложности.",
          block4_3:"Каждый реализуемый проект соответствует стратегическому плану нашей компании и базируется на максимальном удовлетворении потребностей Заказчика. При этом исследуются, анализируются и учитываются его индивидуальные пожелания и замечания с корректировкой в последующем процессе работ.",
          block4_4:"",
          block4_5:"",
          block4_6:"",
          block4_7:"",
          block4_9:"",
          block4_9:"",
//Конец Управление 

//Проекты
          block5:'НАШИ ПРОЕКТЫ:',
          block5_1:'ТОО «Павлодарский нефтехимический завод»:', 
          block5_2:'Установка стационарных газоанализаторов непрерывного контроля за эмиссиями.',
          block5_3:'Строительство печи установки производства битумов. ',
          block5_4:'Техническое перевооружение технологических печей ЛК-6У КТ-1.',
         
          block5_5:'ТОО «Атырауский нефтеперерабатывающий завод»:',
          block5_6:'Оснащение производственных объектов контрольными приборами учета.',
          block5_7:'Оснащение резервуаров РП №6 приборами учета.',
          block5_8:'Техническое перевооружение печей установки по первичной переработке нефти ЭЛОУ-АТ-2.',
          
          block5_9:'ТОО «PetroKazakhstanOilProducts»:',
          block5_9_1:'Установка приборов измерения температуры, давления и расхода пара и возврата конденсата на паровом узлах №2, №4, №5, №16.',
          block5_9_2:'Проект монтажа газоуравниельной линии парка хранения СУГ. Проект монтажа перемычки. Проект монтажа линии пара и инертного газа.',
         

          block5_10_1:"	ТОО «Шымкентская Химическая Компания»:",
          block5_10_2	:"Выполнение Авторского надзора на объекте «Строительство завода по производству МТБЭ.",
         
          block5_10_3	:"ТОО «Тарбагатай Мунай»:",
          block5_10_4	:"Строительство Пункта Сбора Нефти (ПСН) наместорождении «Сарыбулак».",
          
          block5_10_5:"Заказчик ТОО «КазГерм-Сервис»",
          block5_10_6	:"Строительство АГНКС по адресу: город Шымкент, ул. Кайкельды батыра, здание 19/1.",
          
          block5_10_7	:"Заказчик ТОО «Сагиз Петролиум»",
          block5_10_8	:"Строительство приемо-сдаточного пункта Копа с магистральным нефтепроводом от ЦППН до ПСП «Копа» и вахтовым поселком на 20 человек в Байганинском районе, Актюбинской обл.",
          
          block5_10_9	:"Заказчик ТОО ProsperaResources",
          block5_10_10:"Нефтеналивной терминал Сагиз (ННТ).",
          block5_10_11:"",
          
          block5_10_12:"Заказчик Китайская нефтяная корпорация по Технике и Развитию",
          block5_10_13:"Технико-экономическое обоснование ( ТЭО) объекта Строительство завода по производству ЭТБЭ/МТБЭ и порошкового полипропилена.",
          
          block5_10_14:"Заказчик ТОО «Кнауф Гипс Капчагай»",
          block5_10_15:"Газоснабжение завода по производству гипсокартонных плит и сухих строительных смесей ТОО Кнауф Гипс Капчагай.",
          
          block5_10_16:"Заказчик ТОО KAZPETROLEUM TECHNOLOGIES (Казпетролеумтехнолоджис)",
          block5_10_17:"Проектно-изыскательские работы Оснащение производственных объектов ТОО Атырауский нефтеперерабатывающий завод контрольными приборами.",
          
          block5_10_18:"Заказчик ТОО «CaspiOilGas»",
          block5_10_19:"Oбустройство нефтяного месторождения «Ракушечное» в период разведки пробной эксплуатации. Пункт подготовки нефти на нефтяном месторождении «Ракушечное».",
          
          block_5_10_20:"Заказчик «Alties Petroleum International B.V.»",
          block_5_10_21:"Oбустройство нефтяного месторождения «Бесболек» на период разведки и добычи.",
          block_5_10_22:"Строительство нефтепровода с месторождения «Акжар» до места врезки в магистральный нефтепровод АО «КазТрансОйл».",
          block_5_10_23:"Пункт приема и транспортировки нефти «Карсак».",
          block_5_10_24:"Технологический регламент эксплуатации приемо-сдаточного пункта «Карсак».",
          block_5_10_25:"Проектирование воздушной линии электропередачи напряжением 35 киловольт от существующей подстанции Жаркамыс до проектируемой подстанции «Акжар».",
          
          block_5_10_26:"Заказчик ТОО «Бузачи Нефть»",
          block_5_10_27:"Разработка и реализация программы утилизации попутно добываемого газа на газонефтяных месторождениях «Каратурун Морской» и «Каратурун Восточный».",
          block_5_10_28:"Заказчик АО «Казхиммонтаж холдинг» (Agip KCO)",
          block_5_10_29:"Обустройство Восточно-Кашаганского нефтегазового месторождения. Резервуары вертикальные стальные объемом 1500 м³; 1800 м³; 2200 м³ и 5800 м³. для хранения воды на площадке инженерного обеспечения городка строителей.",
          block_5_10_30:"	",
          
          block_5_10_31:"Заказчик ТОО «Казахский институт транспорта нефти и газа»",
          block_5_10_32:"По просьбе и поручению акционеров ТОО «КИТНГ», в период с дек. 2007 г. по июнь 2011 г., группой руководящих работников и главных специалистов компании «MG Engineering» реализованы значимые этапы работ по управлению проектом «Проектирование эффективной организации» к выполнению комплекса проектных работ:",
          block_5_10_33:"«Строительство магистрального газопровода Казахстан – Китай».",
          block_5_10_34:"«Строительство магистрального нефтепровода Кенкияк – Кумколь».",
          
          block_5_10_35:"Заказчик «Big Sky Energy Corp.»",
          block_5_10_36:"Обустройство нефтяного месторождения «Морское».",
          
          block_5_10_37:"Заказчик Lancaster Petroleum (АО «ККМ Operating Company»)",
          block_5_10_38:"Сбор и транспортировка продукции со скважин нефтяного месторождения «Кокжиде-Надсолевое».",
          block_5_10_39:"Расширение цеха подготовки нефти на нефтяном месторождении «Кокжиде-Надсолевое».",
          block_5_10_40:"Новый вахтовый поселок проживания обслуживающего персонала.",
          block_5_10_41:"Разработка нормативно технической документации «Технологические потери нефти и газа при сборе, транспортировке и подготовке на промышленных объектах АО «ККМ Operating Company».",
          
          block_5_10_42:"Заказчик АО «CNPC-Актобемунайгаз»",
          block_5_10_43:"Объекты и сооружения системы автоматического герметичного налива и слива нефти на железнодорожной станции «Бестамак».",
          
          block_5_10_44:"Заказчик АО «Имсталькон»",
          block_5_10_45:"Строительство технологических резервуаров вертикальных стальных центрального пункта подготовки нефти на газонефтяном месторождении «Каражанбас».",
          block_5_10_46:"Реконструкция резервуарного парка насосной промежуточной станции «Тенгиз».",
          block_5_10_47:"Резервуарный парк очищенной кислой воды и общего техобслуживания на нефтяном месторождении «Тенгиз».",
          block_5_10_48:"	",
          
          block_5_10_49:"Заказчик АО «Каражанбасмунай»",
          block_5_10_50:"Установка групповых замерных установок.",
          block_5_10_51:"Строительство новых технологических объектов на газонефтяном месторождении «Каражанбас».",
          block_5_10_52:"Расширение резервуарного парка дожимной насосной станции на газонефтяном месторождении «Каражанбас».",
          block_5_10_53:"Расширение центрального пункта подготовки нефти.",
          
          block_5_10_54:"Заказчик АО «Каспий Нефть ТМЕ»",
          block_5_10_55:"Сбор и транспортировка продукции скважин нефтегазоконденсатное месторождения «Алибек Южный».",
          block_5_10_56:"Расширение пункта налива нефти на месторождении «Алибек Южный».",
          block_5_10_57:"Установка закачки воды на месторождении «Алибек Южный».",
          block_5_10_58:"Внутрипромысловые дороги на месторождении «Алибек Южный».",
          block_5_10_59:"Реконструкция подстанции ПС-110/35/10 кВ «Кенкияк» и другие сооружения и устройства энергообеспечения.",
          
          block_5_10_60:"Заказчик АО «Конденсат»",
          block_5_10_61:"Ремонт и замена участка газопровода неочищенного газа ГП-3-Оренбург.",
          block_5_10_62:"Разработка концептуального проекта фирменной автозаправочной станции, г. Уральск.",
          
          block_5_10_63:"Заказчик АО «МонтажСпецСтрой»",
          block_5_10_64:"Разработка тендерной документации «Расширение установки забора морской воды».",
          block_5_10_65:"Блочно-модульное здание временного Офиса ТОО «Тенгизшевройл».",
          block_5_10_66:"Газотурбинная электростанция на газонефтяном месторождении «Дунга».",
          
          block_5_10_67:"Заказчик ТОО «BN Мунай»",
          block_5_10_68:"Обустройство газового месторождения «Кзылой» в Актюбинской области.",
          block_5_10_69:"Внутрипромысловые газосборные трубопроводы.",
          
          block_5_10_70:"Заказчик ТОО «Dostyk Refinery»",
          block_5_10_71:"Железнодорожная экспортно-перевалочная нефтебаза светлых нефтепродуктов «Достык-2».",
          block_5_10_72:"Реконструкция экспортно-железнодорожной перевалочной нефтебазы «Достык».",
          block_5_10_73:"",
          
          block_5_10_74:"Заказчик ТОО «Prosystems Petroleum»",
          block_5_10_75:"Реконструкция сервисной автозаправочной станции, г. Алматы.",
          
          block_5_10_76:"Заказчик ТОО «Sinooil»",
          block_5_10_77:"Реконструкция базы нефтепродуктов г. Нур-Султан.",
          block_5_10_78:"Сервисная автозаправочная станция, Алматинская область, Карасайский район, трасса Каскелен-Шамалган.",
          block_5_10_79:"Сервисная автозаправочная станция, Алматинской области, Енбекшиказахский район.",
          block_5_10_80:"Сервисная автозаправочная станция, Алматинская область, Жамбыльский район, село Узунагаш.",
          
          block_5_10_81:"Заказчик ТОО «Артис Оверсис С.А.Казахстан»",
          block_5_10_82:"Расширение нефтяного терминала. Морской порт г. Актау.",
          
          block_5_10_83:"Заказчик ТОО «Жаикмунай»",
          block_5_10_84:"Объекты и сооружения обустройства Чинаревского нефтегазоконденсатного месторождения.",
          
          block_5_10_85:"Заказчик ТОО «Казахойл Актобе»",
          block_5_10_86:"Обустройство нефтегазоконденсатного месторождения «Кожасай» на период пробной эксплуатации.",
          block_5_10_87:"Пункт приема и подготовки нефти на нефтегазоконденсатном месторождении «Алибекмола».",
          block_5_10_88:"Система утилизации промышленных стоков путем их закачки в пласт на месторождении «Алибекмола».",
          block_5_10_89:"Система поддержания пластового давления в северной части нефтегазоконденсатного месторождения «Алибекмола».",
          block_5_10_90:"Разработка технологического регламента по эксплуатации пункта подготовки нефти на месторождении «Алибекмола».",
          block_5_10_91:"Система энергоснабжения на месторождении «Кожасай».",
          block_5_10_92:"Воздушные линии электропередач напряжением шесть киловольт с подстанцией «Южный Алибекмола» для электроснабжения Центрального пункта подготовки нефти и газа месторождения «Алибекмола».",
          block_5_10_93:"",
          
          block_5_10_94:"Заказчик ТОО «Кен-Сары»",
          block_5_10_95:"Обустройство нефтяного месторождения «Арыстановское», в период разведки и пробной эксплуатации. I-й, II-й пусковой комплекс.",
          block_5_10_96:"Обустройство месторождения «Арыстановское». III-й пусковой комплекс.",
          block_5_10_97:"Пункт подготовки нефти месторождения «Арыстановское».",
          
          block_5_10_98:"Заказчик ТОО «Казахстанско-Китайский Трубопровод»",
          block_5_10_99:"Строительство нефтепровода Казахстан-Китай. Участок Кенкияк-Кумколь. «Объединенный аварийно-восстановительный пункт».",
          
          block_5_10_100:"Заказчик ТОО «МегаТрансАзия»",
          block_5_10_101:"Расширение производственных мощностей нефтебазы. Цех переработки нефтепродуктов на ст. Бесколь Алакольского р-на Алматинской области.",
          block_5_10_102:"Завод по переработке нефти мощностью 1 100 000 т/год.",
          block_5_10_103:"Завод по переработке нефти мощностью 600 000 т/год.",
         
          block_5_10_104:"Заказчик ТОО «НТ Нурлы Мунай»",
          block_5_10_105:"Приёмо-сдаточный пункт нефти «НТ Нурлы Мунай».",
          
          block_5_10_106:"Заказчик ТОО «Сагиз Петролеум Компани»",
          block_5_10_107:"Оценка объемов и стоимости строительно-монтажных работ строительства приемо-сдаточного пункта «Ebity». Блокировочный трубопровод с врезкой в магистральный нефтепровод «Кенкияк-Атырау» в Байганинском районе Актюбинской обл.",
          
          block_5_10_108:"Заказчик ТОО «Саутс Ойл»",
          block_5_10_109:"Строительство коммерческого узла учета нефти и нефтепровода на нефтяном месторождении «Кенлык» с подключением к магистральному нефтепроводу Кенкияк-Кумколь.",
          
          block_5_10_110:"Заказчик ТОО «ТЭК Казахстан»",
          block_5_10_112:"Реконструкция топливных складов экипировки локомотивов на железнодорожных станциях Достык, Уштобе, Костанай.",
          
          block_5_10_113:"Заказчик ТОО «Умай-Маркет»",
          block_5_10_114:"Распределительный железнодорожный терминал светлых нефтепродуктов г. Арысь;",
          block_5_10_115:"Техническое сопровождение и управление строительством.",
          block_5_10_116:"Заказчик ТОО «Эрнст энд Янг консультационные услуги»",
          block_5_10_117:"Разработка технико-экономического обоснования на строительство и эксплуатацию 95 автомобильных газонаполнительных компрессорных станций в Республике Казахстан.",
          

//Конец Проекты      
//Cертификаты------------------------------------    
          cert:"CЕРТИФИКАТЫ И ЛИЦЕНЗИИ",
          cert0:"Сертификаты",
          cert1:"Сертификат СТ РК OHSAS 18001:2008 «Система менеджмента профессиональной безопасности и здоровья», выданный ТОО «Центр сертификации ISOKZ».",
          cert2:"Сертификат СТ РК ISO 14001:2015 «Система экологического менеджмента», выданный ТОО «Metrology&Certification».",
          cert3:"Сертификат СТ РК ISO 9001-2016 (ISO 14001:2015) «Система менеджмента качества», выданный ТОО «Metrology&Certification».",
          cert4:"",
          cert5:"",
          cert6:"",

          lic0:"Лицензии",
          lic1:"Государственная лицензия 14016989 Ⅰ-й (первой) категории (генеральная) от 10.11.2014 г. на проектирование, выданная Комитетом по делам строительства и жилищно-коммунального хозяйства и управления земельными ресурсами. Министерство национальной экономики Республики Казахстан.",
          lic2:"Государственная лицензия 14001279 (генеральная) от 04.02.2014 г. на проектирование (технологическое) и (или) эксплуатация горных (разведка, добыча полезных ископаемых), нефтехимических, химических производств, проектирование (технологическое) нефтегазоперерабатывающих производств, эксплуатация магистральных газопроводов, нефтепроводов, нефтепродуктопроводов, выданная Министерством нефти и газа Республики Казахстан. Комитет государственной инспекции в нефтегазовом комплексе Министерства нефти и газа Республики Казахстан.",
          lic3:"Лицензия 01625Р (генеральная) от 23.01.2014 г. на выполнение работ и оказание услуг в области охраны окружающей среды, выданная Министерством окружающей среды и водных ресурсов Республики Казахстан. Комитет экологического регулирования и контроля Министерства окружающей среды и водных ресурсов Республики Казахстан. ",
          lic4:"Государственная лицензия 21018179 20 от 19.05.2021 г. на изыскательскую деятельность. Инженерно-геологические и инженерно-гидрогеологические работы, в том числе:",
          lic4_0:"Инженерно-геологические и инженерно-гидрогеологические работы, в том числе:",
          lic4_1:"полевые исследования грунтов, гидрогеологические исследования;",
          lic4_2:"геофизические исследования, рекогносцировка и съемка.",
          lic4_3:"Инженерно-геодезические работы, в том числе:",
          lic4_4:"топографические работы для проектирования и строительства (съемки в масштабах от 1:10000 до 1:200, а также съемки подземных коммуникаций и сооружений, трассирование и съемка наземных линейных сооружений и их элементов);",
          lic4_5:"геодезические работы, связанные с переносом в натуру с привязкой инженерно-геологических",
          lic4_6:"выработок, геофизических и других точек изысканий;",
          lic4_7:"построение и закладка геодезических центров;",
          lic4_8:"создание планово-высотных съемочных сетей.",
          
          //Конец сертификаты---------------------------------------------
          
         //Деятельность-----------------------------------------------
         
         act0:'Деятельность',
         act:'Деятельность компании направлена на качественное выполнение комплекса инженерных и проектных работ строительства новых, реконструкции и модернизации уже действующих объектов, сооружений и предприятий нефтегазовой отрасли.Компания открыта для сотрудничества, готова принять любые взаимовыгодные предложения в области эффективной реализации проектов добычи, подготовки, транспорта нефти и газа как в Казахстане, так и за его пределами.',
         act1:'КОМПЛЕКСНОЕ ПРОЕКТИРОВАНИЕ:',
         act1_1:'обустройства нефти-газо-конденсатных месторождений:',
         act1_2:'обустройство скважин;',
         act1_3:'систем промыслового сбора скважинной продукции;',
         act1_4:'замерных установок;',
         act1_5:'установок первичного сброса пластовой воды;',
         act1_6:'систем поддержания пластового давления;',
         act1_7:'кустовых насосных и компрессорных станций.',
        act1_8:'объектов подготовки нефти и газа до товарной кондиции:',
         act1_9:'установок подготовки нефти и газа;',
         act1_10:'установок приема и учета поступаемой продукции;',
         act1_11:'установок сепарации, в том числе дегазации,',
         act1_12:'обезвоживания, обессоливания и демеркаптанизации нефти;',
         act1_13:'установок очистки и осушки газа;',
         act1_14:'установок предварительной подготовки газа (УППГ);',
         act1_15:'установок комплексной подготовки газа (УКПГ);',
         act1_16:'установок приготовления растворов и компонентов, сопутствующих очистки нефти и газа;',
         act1_17:'установок очистки пластовой воды;',
         act1_18:'технологических насосных и компрессорных станций;',
         act1_19:'резервуарных парков сбора готовой продукции;',
         act1_20:'насосных и компрессорных станций перекачки готовой продукции.',
        
         act1_21:'объектов и сооружений транспорта нефти и газа:',
         act1_22:'магистральных трубопроводов любого назначения;',
         act1_23:'промысловых, технологических, распределительных трубопроводов нефти и газа.',
         act1_24:'объектов переработки нефти, газа и углеводородного сырья;',
         act1_25:'объектов хранения, распределения и реализации нефтепродуктов;',
         act1_26:'объектов хранения, распределения и реализации газа;',
         act1_27:'других объектов, входящих в инфраструктуру нефтедобычи, трубопроводного транспорта, промышленных и гражданских сооружений;',
         act1_28:'систем противопожарной и электрохимической защиты;',
        
         act1_29:'ТЕХНИКО-ЭКОНОМИЧЕСКИЕ ОБСЛЕДОВАНИЯ:',
         act1_30:'технико-экономические расчеты;',
         act1_31:'технико-экономические обоснования инвестиций.',
         act1_32:'ТЕХНИЧЕСКИЕ ОБСЛЕДОВАНИЯ:',
         act1_33:'существующих объектов нефтегазового комплекса с оценкой их воздействия на окружающую среду;',
         act1_34:'разработка рекомендаций по ремонту, реконструкции и модернизации обследуемых объектов нефтегазового комплекса.',
         act1_35:'ТЕХНИЧЕСКИЙ И АВТОРСКИЙ НАДЗОР ЗА СТРОИТЕЛЬСТВОМ',
         act1_36:'РАЗРАБОТКА НОРМАТИВНОЙ, ОБЗОРНО-АНАЛИТИЧЕСКОЙ ДОКУМЕНТАЦИИ',
         act1_37:'ИНЖИНИРИНГОВЫЕ УСЛУГИ:',
         act1_38:'подготовка документации и проведение тендеров;',
         act1_39:'получение и оформление исходных данных;',
         act1_40:'составление технических условий;',
         act1_41:'подготовка документации и выполнение согласований;',
         act1_42:'поставка оборудования для нефтегазовой отрасли;',
         act1_43:'управление строительством, реализация «под ключ» отдельных объектов нефтегазового комплекса, промышленного и гражданского строительства.',
                
      
         //Деятельность--------------------------------------
          

          //contacts--------------------------------------------------
          cont:'Адрес',
          cont2:'Республика Казахстан г.Алматы, 050052, мкр. Таугуль-3, ул. Жантурина 23',
          cont3:'Телефон:',
          cont4:'+7 (727) 239 12 33, +7 (727) 239 77 21, +7 (727) 239 77 24',
          cont5:'',
          cont6:'Email: info@mgeng.kz',
          map:'Схема проезда:',
          //contacts--------------------------------------------------

          welcome: "Текст на русском языке <br/> <strong>Текст на русском языке</strong>",
          a: "Текст на русском языке",
          menuItem1:'ГЛАВНАЯ',
          menuItem2:'О КОМПАНИИ',
          menuItem3:'УПРАВЛЕНИЕ',
          menuItem4:'ПРОЕКТЫ',
          menuItem5:'ДЕЯТЕЛЬНОСТЬ',
          menuItem6:'СЕРТИФИКАТЫ И ЛИЦЕНЗИИ',
          menuItem7:'КОНТАКТЫ'
        }
      },
//--------------------------------------------------------------------------------------------------------
      kz: {
        translations: {
          "Текст на казахском":
            "TТекст на казахском",
          "Текст на казахском": "Текст на казахском",
          welcome: "Текст на казахском <br/> <strong>Текст на казахском</strong>",
          a: "Текст на казахском",
          menuItem1:'БАС БЕТ',
          menuItem2:'КОМПАНИЯ ТУРАЛЫ',
          menuItem3:'БАСҚАРУ',
          menuItem4:'ЖОБАЛА',
          menuItem5:'ҚЫЗМЕТ',
          menuItem6:'СЕРТИФИКАТТАР ЖӘНЕ ЛИЦЕНЗИЯЛАР',
          menuItem7:'БАЙЛАНЫСТАР',

          block0:"Әлемде ең",
          block0_1:"заманауи технологиялар...",
          block0_2:"тиімді талдамалар…",
          
          block0_3:"Сіздердің көбіңіз бүгін біздің компаниямыз туралы алғаш рет естиді, ал бізбен ынтымақтасып келе жатқан адамдар, мүмкін, жасаған таңдауының дұрыс екеніне тағы да бір рет көз жеткізер. Жұмыс істеген уақыт ішінде біз белгілі бір табыстар мен жетістіктерге қол жеткіздік, өз қызметтерімізді ұсынатын нарықты кеңейттік. Біздің ынтымақтастығымыздың шекаралары қазір отандық серіктестерді де, шетелдік серіктестерді де қамтиды. Ынтамыз, жұмысқа берілгендігіміз, білікті қызметкерлеріміз, компанияға деген адалдығымыз бен тапсырыс берушінің мүдделеріне қамқорлық жасау – алға қойылған мақсаттарға қол жеткізудің жолы, міне, осы. Сіздерді біздің тәжірибемізбен және өндірістік ресурстарымызбен танысуға шақырамыз және «MG Engineering инженерлік фирмасы» Сіздердің алға қойған міндеттеріңізді табысты жүзеге асыруда пайдалы болатынына сенім білдіреміз. Біз барлық қызығушылық танытқан компаниялармен ынтымақтасуға дайынбыз және өзара пайдалы серіктестік қарым-қатынастарды құруда және оларды нығайтуда барынша қолдау көрсететін боламыз.",
          block0_4:"Бизнесте табысқа қол жеткізіңіздер деген ақ тілектермен",
          block0_5:"«MG Engineering инженерлік фирмасы» ұжымы",
          block1:"«MG Engineering инженерлік фирмасы» ЖШС мұнай-газ және құрылыс салаларында ұзақ уақыт жұмыс істеген мамандар тобының бастамасымен 2002 ж. тамыз айында құрылды. 2013 ж. желтоқсан айына дейін компания «Мұнай Графика» деген атаумен белгілі болды, бірақ қайта құрылымдау аяқталғаннан кейін атауы өзгертіліп, кәсіби қызметінің негізгі бағытын бейнелейтін жаңа атаумен жұмысын жалғастырды.",

          block1_1:"«MG Engineering инженерлік фирмасы» инженерлік-техникалық талдамалар мен жобалау, материалдық-техникалық қамтамасыз ету мен құрамдау, мұнай-газ құрылыстары мен кәсіпорындарының құрылыс өндірісін басқару саласында маманданады.",

          block1_2:"Негізгі мақсаты – тапсырыс берушінің барлық талаптарын қамтамасыз ететін және қанағаттандыратын инженерлік қызметтер кешенін сапалы түрде орындау (барынша қысқа және кепілдік етілген мерзім ішінде).",
          block2:"Алға қойылған мақсаттарға қол жеткізу үшін «MG Engineering инженерлік фирмасы» ЖШС-ның қалыптасқан әлеуеті бар. Өз жұмысымызда біз барлық қол жетерлік мүмкіндіктер мен құралдарды пайдаланамыз, соның ішінде:",
          block2_1:"жаңа технологияларды қолдану;",
          block2_2:"қолданыстағы, сертификатталған сапаны басқару жүйесі;",
          block2_3:"қызметерлердің кәсіби деңгейі мен біліктілігін әрдайым көтеріп отыру;",
          block2_4:"инновациялық тәсілдер мен заманауи техникалық шешімдер;",
          block2_5:"жобалауды тиімді басқару;",
          block2_6:"бұрынғы жүзеге асырылған жобалардың нақты тәжірибесін пайдалану.",
          block3:"Нарық жағдайында жұмыс істей отырып және бәсекеге қабілеттілікті көтерудің қажет екенін ұғына отырып, біз көрсететін қызметтеріміздің сапасын әрдайым жақсартып отыруға үлкен мән береміз. Сапа стандарттарын сақтай отырып, мерзімді барынша қысқарту үшін «MG Engineering инженерлік фирмасы» ЖШС жобалаудағы заманауи технологияларды кең ауқымда пайдаланады.",
          block3_1:"Көптеген маңызды жобаларды жүзеге асырып, біз өз қызметтеріміз ұсынылатын нарықты едәуір кеңейттік те, онда белгілі бір тауашаны иелендік. Біздің ынтымақтастығымыздың шекаралары қазір отандық серіктестерді де, шетелдік серіктестерді де қамтиды.",
          block3_2:"Табысты қызметтің өлшемдері:",
          block3_3:"тапсырыс берушінің біздің қызметтерімізге қызығушылық танытуы;",
          block3_4:"көрсетілетін қызметтердің тізімін анық тұжырымдау;",
          block3_5:"орындалатын жұмыстардың талдау мен жобалаудан бастап нысанды «кілтке дейін» әдісімен тапсыруға дейінгі толық көлемі;",
          block3_6:"мердігерлік жұмыстар мен қызметтерді мұқият жоспарлау және орындалуын бақылау;",
          block3_7:"жобаны сатыларға бөлу;",
          block3_8:"тапсырыс беруші-серіктеспен жұмыс істеу барысында тығыз өзара әрекеттесу;",
          block3_9:"тапсырыс берушінің үміттерінің нақтылығы және орындылығы;",
          block3_10:"ұжымның әрбір мүшесінің алға қойылған міндеттер мен мақсаттарды анық ұғынуы;",
          block3_11:"еңбекқор, білікті, жұмысты барынша жақсы орындауға бет қойған қызметкерлер.",
          
          block4:"КОМПАНИЯ ТУРАЛЫ",
          block4_1:"Біздің компания Ресей, Украина, Латвия, Канада және Германия жобалау институттарымен тұрақты негізде жұмыс істейді.",

          block4_2:"2002 жылдан бастап компания мұнай-газ кәсіпорындарының, нысандары мен ғимараттарының құрылысының күрделілігі әртүрлі 150-ден астам жобасын жүзеге асыруға қатысты.",

          block4_3:"Әрбір жүзеге асырылатын жоба біздің компаниямыздың стратегиялық жоспарына сәйкес келеді және Тапсырыс берушінің қажеттеліктерін барынша қанағаттандыруды негізге алады. Әрі оның жекелеген тілектері мен ескертулері болашақтағы жұмыс үрдісінде түзетіле отырып, зерттелінеді, талданады және ескеріледі.",
          
          block5:"БІЗДІҢ ЖОБАЛАР:",
          block5_1:"«Павлодар мұнай-химия зауыты» ЖШС:",
          block5_2:"шығарындыларды үздіксіз бақылау үшін стационарлық газ анализаторларын орнату.",
          block5_3:"Битум өндіретін қондырғы үшін пештің құрылысы.",
          block5_4:"ЛК-6У КТ-1 технологиялық пештерін техникалық қайта жабдықтау.",
          block5_5:"«Атырау мұнай өңдеу зауыты» ЖШС:",
          block5_6:"өндірістік қондырғыларды бақылау -өлшеу аспаптарымен жабдықтау.",
          block5_7:"No6 РП резервуарларын өлшеу құралдарымен жабдықтау.",
          block5_8:"ЭЛОУ-АТ-2 майды бастапқы өңдеуге арналған қондырғы пештерін техникалық қайта жабдықтау.",
          block5_9:"«ПетроҚазақстан Ойл Продактс» ЖШС:",
          block5_9_1:"No2, No4, No5, No16 бу қондырғыларында температураны, қысымды және бу шығынын және конденсаттың қайтарылуын өлшеуге арналған аспаптарды орнату.",
          block5_9_2:"СКГ сақтау паркінің газды теңестіру желісін орнату жобасы. Линтельді орнату жобасы. Бу және инертті газ құбырын орнату жобасы.",
          block5_10_1:"«Шымкент химия компаниясы» ЖШС:",
          block5_10_2:"«MTBE өндіру зауытының құрылысы» объектісінде конструкторлық қадағалауды енгізу.",
          block5_10_3:"«Тарбағатай Мұнай» ЖШС:",
          block5_10_4:"Сарыбұлақ кен орнында мұнай жинау пунктінің құрылысы.",
          block5_10_5:"Тапсырыс беруші ЖШС «KazGerm-Service»",
          block5_10_6:"Шымкент қаласы, ул. Қайкелді батыр, 19/1 ғимарат.",
          block5_10_7:"Тапсырыс беруші «Sagiz Petroleum» ЖШС",
          block5_10_8:"КФА -дан «Копа» ПСП магистральды мұнай құбырымен Копа қабылдау және жеткізу пунктінің құрылысы және Ақтөбе облысы, Байғанин ауданында 20 адамға арналған ауысымдық лагерь.",
          block5_10_9:"Тапсырыс беруші ЖШС «ProsperaResources»",
          block5_10_10:"«Сағыз мұнай терминалы» (ҰҰТ).",
          block5_10_12:"Тапсырыс беруші Қытайдың Мұнай және газ өнеркәсіп корпорациясы",
          block5_10_13:"«ETBE / MTBE және ұнтақ полипропиленді өндіру зауытының құрылысы» объектінің техникалық-экономикалық негіздемесі (техникалық-экономикалық негіздеме).",
          block5_10_14:"Тапсырыс беруші «Кнауф Гипс Қапшағай» ЖШС",
          block5_10_15:"Зауытты гипсокартондар мен құрғақ құрылыс қоспаларын өндіруге газбен қамтамасыз ету",
          block5_10_16:"Тапсырыс беруші ЖШС «KAZPETROLEUM TECHNOLOGIES»(Казпетролеумтехнолоджис)",
          block5_10_17:"Атырау мұнай өңдеу зауыты» ЖШС өндірістік қондырғыларын басқару құрылғыларымен жабдықтау »жобалау-іздестіру жұмыстары.",
          block5_10_18:"Тапсырыс беруші «CaspiOilGas» ЖШС",
          block5_10_19:"Ракушечное мұнай кен орнын барлау және сынақ жұмыстары кезінде орналастыру.'Ракушечное' мұнай кен орнындағы мұнай дайындау станциясы.",
          block5_10_20:"Тапсырыс беруші «Alties Petroleum International B.V.»",
          block5_10_21:"Барлау және өндіру кезеңінде Бесболек мұнай кен орнын игеру;",
          block5_10_22:"«Ақжар» кен орнынан АҚ «ҚазТрансОйл» магистральдық мұнай құбырына дейін бекіту нүктесіне дейін мұнай құбырын салу;",
          block5_10_23:"«Қарсақ» мұнай қабылдау және тасымалдау пункті;",
          block5_10_24:"Қарсақ қабылдау пунктінің жұмысының технологиялық регламенттері;",
          block5_10_25:"Қолданыстағы Жарқамыс қосалқы станциясынан жобаланып жатқан «Ақжар» қосалқы станциясына дейін 35 киловольт кернеуі бар электр берудің әуе желісін жобалау",
          block5_10_26:"Тапсырыс беруші ЖШС «Бузачи Нефть»",
          block5_10_27:"«Қаратұрын Теңіз» және «Қаратұрын Шығыс» газ-мұнай кен орындарында ілеспе шығарылатын газды кәдеге жарату бағдарламасын әзірлеу және жүзеге асыру.",
          block5_10_28:"Тапсырыс беруші АҚ «Казхиммонтаж холдинг» (Agip KCO)",
          block5_10_29:"Шығыс Қашаған мұнай-газ кен орнын жайластыру. Құрылысшылар қалашығын инженерлік қамтамасыз ету алаңшасында су сақтауға арналған көлемі 1500м; 1800м³; 2200м³ және 5800м³ тік болат сұйыққоймалар.",
          block_5_10_31:"Тапсырыс беруші ЖШС «Казахский институт транспорта нефти и газа»",
          block_5_10_32:"ЖШС «KITNG» акционерлерінің өтініші мен нұсқауы бойынша желтоқсан айынан бастап. 2007 жылдан 2011 жылдың маусымына дейін MG Engineering компаниясының бір топ басшылары мен бас мамандары жобалау жұмыстарының кешенін жүзеге асыру үшін «Тиімді ұйымды жобалау» жобалық басқару бойынша жұмыстардың маңызды кезеңдерін жүзеге асырды:",
          block_5_10_33:"«Қазақстан - Қытай магистральды газ құбырының құрылысы»;",
          block_5_10_34:"«Кеңқияқ - Құмкөл магистральды мұнай құбырының құрылысы».",
          block_5_10_35:"Тапсырыс беруші «Big Sky Energy Corp.»",
          block_5_10_36:"«Теңіз» мұнай кен орнын жайластыру.",
          block_5_10_37:"Тапсырыс беруші Lancaster Petroleum ( АҚ «ККМ Operating Company»)",
          block_5_10_38:"«Көкжиде-Тұз үсті» мұнай кен орнының ұңғымаларынан өнім жинау және тасымалдау;",
          block_5_10_39:"«Көкжиде-Тұз үсті» мұнай кен орнында мұнай дайындау цехін кеңейту;",
          block_5_10_40:"Қызмет көрсететін жұмысшылар тұратын жаңа вахталық қыстақ;",
          block_5_10_41:"АҚ «ККМ Operating Company» өнеркәсіптік нысандарында жинау, тасымалдау және дайындау кезінде мұнай мен газдың технологиялық ысыраптары» нормативтік-техникалық құжаттамасын әзірлеу.",
          block_5_10_42:"Тапсырыс беруші АҚ «CNPC-Актобемунайгаз»",
          block_5_10_43:"«Бестамақ» теміржол станциясында мұнайды автоматты герметикалық құю және қотару жүйесінің нысандары және құрылыстары.",
          block_5_10_44:"Тапсырыс беруші АҚ «Имсталькон»",
          block_5_10_45:"«Қаражанбас» газ-мұнай кен орнында орталық мұнай дайындау пунктінде технологиялық тік болат сұйыққоймаларды салу;",
          block_5_10_46:"«Тенгиз» аралық сорғы станциясының сұйыққоймалық паркін қайта құру;",
          block_5_10_47:"«Тенгиз» мұнай кен орнында тазартылған қышқыл су мен жалпы техникалық қызмет көрсетудің сұйыққоймалық паркі.",
          block_5_10_49:"Тапсырыс беруші «Каражанбасмунай» АҚ",
          block_5_10_50:"Топтық өлшемдік қондырғыларды орнату;",
          block_5_10_51:"«Қаражанбас» газ-мұнай кен орнында жаңа технологиялық нысандарды салу;",
          block_5_10_52:"«Қаражанбас» газ-мұнай кен орнында сықпа сорғы станциясының сұйыққоймалық паркін кеңейту;",
          block_5_10_53:"Орталық мұнай дайындау пунктін кеңейту.",
          block_5_10_54:"Тапсырыс беруші АҚ «Каспий Нефть ТМЕ»",
          block_5_10_55:"«Әлібек Оңтүстік» кен орнының мұнай-газ конденсаттық ұңғымаларының өнімін жинау және тасымалдау;",
          block_5_10_56:"«Әлібек Оңтүстік» кен орнында мұнай құю пунктін кеңейту;",
          block_5_10_57:"«Әлібек Оңтүстік» кен орнында су тартуды орнату;",
          block_5_10_58:"«Әлібек Оңтүстік» кен орнында ішкі кәсіптік жолдар;",
          block_5_10_59:"«Кеңқияқ» ПС-110/35/10 кВ аралық станциясын және қуатпен қамтамасыз етудің басқа да құрылыстары мен құрылғыларын қайта құру.",
          block_5_10_60:"Тапсырыс беруші АҚ «Конденсат»",
          block_5_10_61:"ГП-3-Орынбор тазартылмаған газдың газ құбыры учаскесін жөндеу және алмастыру;",
          block_5_10_62:"Орал каласының Фирмалық жанармай құю станциясының тұжырымдамалық жобасын әзірлеу.",
          block_5_10_63:"Тапсырыс беруші АҚ «МонтажСпецСтрой»",
          block_5_10_64:"«Теңіз суын тарту қондырғысын кеңейту» тендерлік құжаттамасын әзірлеу;",
          block_5_10_65:"ЖШС «Тенгизшевройл» уақытша офисінің блок-модульдік ғимараты;",
          block_5_10_66:"«Дунга» газ-мұнай кен орнындағы газтурбиндік электрстанциясы.",
          block_5_10_67:"Тапсырыс беруші ЖШС «BN Мунай»",
          block_5_10_68:"Ақтөбе облысындағы «Кзылой» газ кен орнын жайластыру;",
          block_5_10_69:"Ішкі кәсіптік газ жинайтын құбырлар.",
          block_5_10_70:"Тапсырыс беруші «Dostyk Refinery» ЖШС",
          block_5_10_71:"«Достық-2» ақшыл түсті мұнай өнімдерінің экспорттық-жүк ауыстырып тиейтін теміржол мұнай базасы;",
          block_5_10_72:"«Достық» экспорттық-жүк ауыстырып тиейтін теміржол мұнай базасын қайта құру.",
          block_5_10_74:"Тапсырыс беруші ЖШС «Prosystems Petroleum»",
          block_5_10_75:"Алматы қаласының сервистік жанармай құю станциясын қайта құру.",
          block_5_10_76:"Тапсырыс беруші ЖШС «Sinooil»",
          block_5_10_77:"Нұр-Сұлтан қ. мұнай өнімдері базасын қайта құру;",
          block_5_10_78:"Сервистік жанармай құю станциясы, Алматы облысы, Қарасай ауданы, Қаскелең-Шамалған трассасы;",
          block_5_10_79:"Сервистік жанармай құю станциясы, Алматы облысы, Еңбекшіқазақ ауданы;",
          block_5_10_80:"Сервистік жанармай құю станциясы, Алматы облысы, Жамбыл ауданы, Ұзынағаш ауылы.",
          block_5_10_81:"Тапсырыс беруші ЖШС «Артис Оверсис С.А. Казахстан»",
          block_5_10_82:"Мұнай терминалын кеңейту. Ақтау қаласындағы теңіз айлағы.",
          block_5_10_83:"Тапсырыс беруші ЖШС «Жаикмунай»",
          block_5_10_84:"Чинарев мұнай-газ конденсаттық кен орнын жайластырудың нысандары мен құрылыстары.",
          block_5_10_85:"Тапсырыс беруші ЖШС «Казахойл Актобе»",
          block_5_10_86:"«Қожасай» мұнай-газ конденсаттық кен орнын сынамалық пайдалану кезеңінде жайластыру;",
          block_5_10_87:"«Әлібекмола» мұнай-газ конденсаттық кен орнындағы мұнай қабылдау және дайындау пункті;",
          block_5_10_88:"«Әлібекмола» кен орнындағы пайдаланған өнеркәсіптік суды қыртысқа қотару жолымен кәдеге жарату жүйесі;",
          block_5_10_89:"«Әлібекмола» мұнай-газ конденсаттық кен орнының солтүстік бөлігінде қыртыс қысымын сүйемелдеу жүйесі;",
          block_5_10_90:"«Әлібекмола» кен орнында мұнай дайындау пунктін пайдалану бойынша технологиялық тәртіпті әзірлеу;",
          block_5_10_91:"«Кожасай» кен орнындағы қуатпен қамтамасыз ету жүйесі;",
          block_5_10_92:"«Әлібекмола» кен орнының Орталық мұнай мен газ дайындау пунктін электрмен қамтамасыз ету үшін «Әлібек Оңтүстік» аралық станциясымен кернеуі алты киловольттық электр берудің әуе желісі.",
          
          



          block_5_10_94:"Тапсырыс беруші ЖШС «Кен-Сары»",
          block_5_10_95:"«Арыстан» мұнай кен орнын барлау және сынамалық пайдалану кезеңінде жайластыру. І-ші, ІІ-ші жіберімді кешен;",
          block_5_10_96:"«Арыстан» кен орнын жайластыру. III-ші жіберімді кешен;",
          block_5_10_97:"«Арыстан» кен орнының мұнай дайындау пункті.",
          block_5_10_98:"Тапсырыс беруші ЖШС «Казахстанско-Китайский Трубопровод»",
          block_5_10_99:"Қазақстан-Қытай мұнай құбырының құрылысы. Кеңқияқ-Құмкөл учаскесі. «Біріктірілген апаттық-қалпына келтіру пункті».",
          block_5_10_100:"Тапсырыс беруші ЖШС «МегаТрансАзия»",
          block_5_10_101:"Мұнай базасының өндірістік қуаттарын кеңейту. Алматы облысы, Алакөл ауданы, Бескөл станциясындағы мұнай өнімдерін өңдеу цехі;",
          block_5_10_102:"Қуаттылығы жылына 1 100 000 т құрайтын мұнай өңдеу зауыты;",
          block_5_10_103:"Қуаттылығы жылына 600 000 т құрайтын мұнай өңдеу зауыты.",
          block_5_10_104:"Тапсырыс беруші ЖШС «НТ Нурлы Мунай»",
          block_5_10_105:"«НТ Нурлы Мунай» мұнай қабылдау-тапсыру пункті.",
          
          block_5_10_106:"«Сагиз Петролеум Компани» ЖШС",
          block_5_10_107:"«Ebity» қабылдау-тапсыру пункті құрылысының құрылыс-монтаж жұмыстарының көлемдері мен құнын бағалау. Тосқауылдық құбыр, Ақтөбе облысы, Байғанин ауданындағы «Кеңқияқ-Атырау» магистральдық мұнай құбырына қосылумен бірге.",
          block_5_10_108:"Тапсырыс беруші ЖШС «Саутс Ойл»",
          block_5_10_109:"Кеңқияқ-Құмкөл магистральдық мұнай құбырына қосыла отырып, «Кеңлік» мұнай кен орнында мұнай мен мұнай құбырын есепке алудың коммерциялық торабының құрылысы.",
          block_5_10_110:"Тапсырыс беруші ЖШС «ТЭК Казахстан»",
          block_5_10_112:"Достық, Үштөбе, Қостанай теміржол станцияларында локомотивтерді жабдықтаудың отын қоймаларын қайта құру.",
          block_5_10_113:"Тапсырыс беруші «Умай-Маркет» ЖШС",
          block_5_10_114:"Арыс қ. ақшыл түсті мұнай өнімдерінің үлестіргіш теміржол терминалы;",
          block_5_10_115:"Техникалық сүйемелдеу және құрылысты басқару.",
          block_5_10_116:"Тапсырыс беруші:«Эрнст энд Янг консультационные услуги» ЖШС",
          block_5_10_117:"Қазақстан Республикасында 95 автомобильдік газ толтырғыш компрессорлық станцияларын салу мен пайдаланудың техникалық-экономикалық негіздемесін әзірлеу.",
          
          act0:"Қызмет",
          act:"Компанияның қызметі мұнай-газ саласының жаңа нысандарының, ғимараттарының және кәсіпорындарының құрылысының, жұмыс істеп тұрған нысандарының, ғимараттарының және кәсіпорындарының қайта құру және жаңғыртуының инженерлік және жобалық жұмыстарының кешенін сапалы түрде орындауға бағытталған.Компания ынтымақтасуға ашық, Қазақстанда да, одан тысқары жерлерде де мұнай бен газды шығару, дайындау, тасымалдау жобаларын тиімді жүзеге асыру саласындағы кез-келген өзара пайдалы ұсыныстарды қабылдауға дайын.",
          act1:"КЕШЕНДІ ЖОБАЛАУ:",
          act1_1:"мұнай-газ конденсаттық кен орындарын жайластыруды:",
          act1_2:"ұңғымаларды жайластыруды;",
          act1_3:"ұңғыма өнімін кәсіптік жинау жобаларын;",
          act1_4:"өлшеу қондырғыларын;",
          act1_5:"қыртыстық суды бастапқы тастау қондырғыларын;",
          act1_6:"қыртыс қысымын сүйемелдеу жүйелерін;",
          act1_7:"бұталы сорғы және компрессорлық станцияларды;",
          act1_8:"мұнай мен газды тауарлық күйге дейін дайындау нысандарын:",
          act1_9:"мұнай мен газ дайындау қондырғыларын;",
          act1_10:"түсетін өнімді қабылдау және есепке алу қондырғыларын;",
          act1_11:"мұнайды бөлу, соның ішінде газсыздандыру, сусыздандыру, тұзсыздандыру",
          act1_12:"және демеркаптандыру қондырғыларын;",
          act1_13:"газ тазарту және кептіру қондырғыларын;",
          act1_14:"алдын ала газ дайындау қондырғыларын (АГДҚ);",
          act1_15:"кешенді газ дайындау қондырғыларын (КГДҚ);",
          act1_16:"мұнай мен газды тазартуға ілесе жүретін ерітінділер мен құрауыштарды",
          act1_17:"дайындау қондырғыларын;",
          act1_18:"қыртыстық суды тазарту қондырғыларын;",
          act1_19:"технологиялық сорғы және компрессорлық станцияларды;",
          act1_20:"дайын өнімді жинаудың сұйыққоймалық парктерін;",
          act1_21:"мұнай мен газ тасымалдау нысандары мен құрылыстарын:",
          act1_22:"кез-келген мақсаттағы магистральдық құбырларды;",
          act1_23:"мұнай мен газдың кәсіптік, технологиялық, үлестіргіш құбырларын;",
          act1_24:"мұнайды, газды және көмірсутек шикізатын өңдеу нысандарын;",
          act1_25:"мұнай өнімдерін сақтау, үлестіру және сату нысандарын;",
          act1_26:"газды сақтау, үлестіру және сату нысандарын;",
          act1_27:"мұнай шығару, құбырлық тасымалдау, өнеркәсіптік және азаматтық құрылыстар инфрақұрылымына кіретін басқа да нысандарды;",
          act1_28:"өртке қарсы және электрлік химиялық қорғау жүйелерін;",
          
          act1_29:"ТЕХНИКАЛЫҚ-ЭКОНОМИКАЛЫҚ ЗЕРТТЕУЛЕР:",
          act1_30:"техникалық-экономикалық есептеулер;",
          act1_31:"инвестициялардың техникалық-экономикалық негіздемелері.",
          act1_32:"ТЕХНИКАЛЫҚ ЗЕРТТЕУЛЕР:",
          act1_33:"мұнай-газ кешенінің қолданыстағы нысандарын олардың қоршаған ортаға әсерін бағалай отырып зерттеу;",
          act1_34:"мұнай-газ кешенінің зерттеліп отырған нысандарын жөндеу, қайта құру және жаңғырту бойынша ұсыныстар әзірлеу.",
          act1_35:"ҚҰРЫЛЫСТЫ ТЕХНИКАЛЫҚ ЖӘНЕ АВТОРЛЫҚ ҚАДАҒАЛАУ",
          act1_36:'РАЗРАБОТКА НОРМАТИВНОЙ, ОБЗОРНО-АНАЛИТИЧЕСКОЙ ДОКУМЕНТАЦИИ',  
          act1_37:"ИНЖИНИРИНГТІК ҚЫЗМЕТТЕР:",
          act1_38:"құжаттама дайындау және тендерлер өткізу;",
          act1_39:"бастапқы директерді алу және рәсімдеу;",
          act1_40:"техникалық шарттарды жасау;",
          act1_41:"құжаттама дайындау және келісулерді орындау;",
          act1_42:"мұнай-газ саласы үшін жабдық жеткізу;",
          act1_43:"құрылысты басқару, мұнай-газ кешенінің, өнеркәсіптік және азаматтық құрылыстың жекелеген нысандарын «кілтке дейін» әдісімен жүзеге асыру.",
          
          cert:"СЕРТИФИКАТТАР ЖӘНЕ ЛИЦЕНЗИЯЛАР",
          cert0:"СЕРТИФИКАТЫ",

          cert1:"Сертификат СТ РК OHSAS 18001:2008 «Система менеджмента профессиональной безопасности и здоровья», выданный ТОО «Центр сертификации ISOKZ».",

          cert2:"Сертификат СТ РК ISO 14001:2015 «Система экологического менеджмента», выданный ТОО «Metrology&Certification».",

          cert3:"Сертификат СТ РК ISO 9001-2016 (ISO 14001:2015) «Система менеджмента качества», выданный ТОО «Metrology&Certification».",
          
          lic0:"ЛИЦЕНЗИЯЛАР",

          lic1:"Құрылыс, тұрғын үй -коммуналдық шаруашылық және жерге орналастыру комитеті берген жобалауға 2014 жылғы 10 қарашадағы 14016989 Ⅰ (бірінші) санаты (жалпы) мемлекеттік лицензия. Қазақстан Республикасының Ұлттық экономика министрлігі.",

          lic2:"02.04.2014 ж. 14001279 (жалпы) тау -кен өндіру (барлау, өндіру), мұнай -химия, химия өндірісі, конструкторлық (технологиялық) мұнай -газ өңдеу өнеркәсібі, газ құбырларын пайдалануға жобалауға (технологиялық) және (немесе) пайдалануға мемлекеттік лицензия, мұнай құбырлары, ҚР Мұнай және газ министрлігі берген мұнай өнімдері құбырлары. Қазақстан Республикасы Мұнай және газ министрлігінің Мұнай -газ кешеніндегі мемлекеттік инспекция комитеті.",

          lic3:"Қазақстан Республикасы Қоршаған орта және су ресурстары министрлігі берген қоршаған ортаны қорғау саласындағы жұмыстарды орындауға және қызметтер көрсетуге 2014 жылғы 23 қаңтардағы 01625R (жалпы) лицензия. Қазақстан Республикасы Қоршаған орта және су ресурстары министрлігінің Экологиялық реттеу және бақылау комитеті.",

          lic4:"Барлау қызметіне 05.19.2021 жылғы 21018179 20 мемлекеттік лицензия.Инженерлік-геологиялық және инженерлік-гидрогеологиялық жұмыстар, оның ішінде:",
          lic4_0:"Инженерлік-геологиялық және инженерлік-гидрогеологиялық жұмыстар, оның ішінде:",
          lic4_1:"топырақтың далалық зерттеулері, гидрогеологиялық зерттеулер;",
          lic4_2:"геофизикалық зерттеулер, барлау және ату.",
          lic4_3:"Инженерлік -геодезиялық жұмыстар, соның ішінде:",
          lic4_4:"жобалау мен құрылысқа арналған топографиялық жұмыстар (1: 10000 -ден 1: 200 масштабындағы геодезия, сондай -ақ жерасты инженерлік желілері мен құрылыстарын түсіру, жердегі сызықтық құрылымдар мен олардың элементтерін іздеу және түсіру);",
          lic4_5:"геотехникалық инженериямен табиғатқа ауысуға байланысты геодезиялық жұмыстарlic4_2:",
          lic4_6:"жұмыс, геофизикалық және басқа зерттеу нүктелері;",
          lic4_7:"геодезиялық орталықтарды салу және төсеу;",
          lic4_8:"жоспарланған биіктіктегі зерттеу желілерін құру.",



          cont:"Мекен жайымыз",
          cont2:"Қазақстан Республикасы,050052 Алматы қ. Таугүл ауданы,Жантурин к-сі, 23 ",
          cont3:'Телефон:',
          cont4:'+7 (727) 239 77 21, +7 (727) 239 12 39, +7 701 318 48 70, +7 701 971 91 99',
          
          cont6:'E-mail:info@mgeng.kz',
         
          map:'Өту сызбасы:',
                    
          
         
                    
                    
          

        }
      },
//-----------------------------------------------------------------------------------------------------------
      en: {
        translations: {
          "To get started, edit <1>src/App.js</1> and save to reload.":
            "Starte in dem du, <1>src/App.js</1> editierst und speicherst.",
          "Welcome to React": "Willkommen bei React und react-i18next",
          welcome: "some text in english <br/> <strong>some text in english</strong>",
          a: "some text in english",
           menuItem1:'HOME',
           menuItem2:'ABOUT COMPANY',
           menuItem3:'MANAGEMENT',
           menuItem4:'PROJECTS',
           menuItem5:'ACTIVITIES',
           menuItem6:'CERTIFICATES AND LICENSES',
           menuItem7:'CONTACTS',



          block0:"The world has the most modern technologies ...",
          block0_1:"effective development ...",
          block0_2:"and there are reliable partners,",
          block0_3:'Many of you will learn about our company for the first time today, and those who cooperate with us may once again be convinced of the correctness of the choice made. During our existence, we have achieved certain successes and achievements, have expanded the market for offering our services. The boundaries of our cooperation now cover the circle of both domestic and foreign partners. Initiative, dedication, qualified personnel, company dedication and customer care are the keys to achieving our goals. We invite you to familiarize yourself with our experience and production resources and express confidence that "Engineering firm MG Engineering" can be useful in the successful implementation of your planned tasks. We are open for cooperation with all interested companies and will provide all-round support in building and strengthening mutually beneficial partnerships.',
          block0_4:"With the best wishes in success in business,",
          block0_5:"MG Engineering Company Team",
          block1:"MG Engineering Company LLP has been formed in August, 2002 at the initiative of a group of specialists worked in oli and gas and construction branches of industry for a long time. Untli December, 2013, the company was known as Munai Grafika. However, after completion of the corporate restructuring, it has been renamed and continues operating under a new name reflecting the main trend of the professional activity.",

          block1_1:"MG Engineering company is specialized in engineering development and design, logistics and procurement, and management of oli and gas structures and enterprise construction.",

          block1_2:"The goal is a qualitative performance (at a maximum tight and guaranteed schedule) of a complex of engineering services ensuring and complying with all customer requirements.",
          block2:"To achieve the goals, MG Engineering Company LLP has a potential already formed. In our work, we apply all avaliable opportunities and tools including:",
          block2_1:"use of the best avaliable technologies;",
          block2_2:"quality management system operating and certified;",
          block2_3:"constant professional and qualification development of the staff;",
          block2_4:"innovative approaches and modern engineering solutions;",
          block2_5:"efficient management of design;",
          block2_6:"application of a practical experience of the previous projects implemented.",
          
          block3:"By operating in the market conditions and realizing a necessity to improve competitiveness, we are making much of the constant improvement of quality of the service provided. To reduce deadlines when complying with the quality standards, the MG Engineering Company LLP is using up-to-date technologies and design in full.",
          block3_1:"By implementing numerous serious projects, we have considerably expanded an offered market of our services. The boundaries of our cooperation cover both local and foreign partners.",
          block3_2:"Criteria of successful activity:",
          block3_3:"the customer interest in our services;",
          block3_4:"clear formulation of the services provided;",
          block3_5:"full scope of works performed from analysis and design to the facliity turnkey operation;",
          block3_6:"contractor works and services thorough planning and performance control;",
          block3_7:"division of project by stages;",
          block3_8:"close cooperation with a customer partner in the course of operations;",
          block3_9:"eality and justifiabliity of the customer expectations;",
          block3_10:"clear understanding of the tasks and goals in hand by every team member;",
          block3_11:"industrious and qualified personnel focused on the best work performance.",



          block4:"ABOUT COMPANY",
          block4_1:"Our company cooperates on a permanent basis with the design institutes of Russian, Ukraine, Latvia, Canada and Germany.",
          block4_2:"Since 2002, the company has participated in implementation of more than 150 projects on construction of oil and gas enterprises, facilities and structures.",
          block4_3:"Each project being implemented complies with the strategic plan of our company. It is based on a maximum satisfaction of the Client needs. However, his individual requests and comments are studied, analyzed and considered with further updates in the course of work.",
          
          
          block5:"OUR PROJECTS:",
          block5_1:"Pavlodar Petrochemical Plant LLP:",
          block5_2:"Installation of stationary gas analyzers for continuous monitoring of emissions.",
          block5_3:"Construction of a furnace for the bitumen production unit.",
          block5_4:"Technical re-equipment of technological furnaces LK-6U KT-1.",
          
          block5_5:"Atyrau Oil Refinery LLP:",
          block5_6:"Equipping production facilities with control metering devices.",
          block5_7:"Equipping reservoirs RP No. 6 with metering devices.",
          block5_8:"Technical re-equipment of furnaces of the unit for primary oil refining ELOU-AT-2.",
          block5_9:"PetroKazakhstan Oil Products LLP:",
          block5_9_1:"Installation of instruments for measuring temperature, pressure and steam consumption and condensate return at steam units No. 2, No. 4, No. 5, No. 16.",
          block_5_9_2:"Project for the installation of the gas leveling line of the LPG storage park. Lintel installation project. Steam and inert gas line installation project.",
          block5_10_1:"Shymkent Chemical Company LLP:",
          block5_10_2:"Implementation of Designer supervision at the facility “Construction of a plant for the production of MTBE.",
          block5_10_3:"Tarbagatai Munai LLP:",
          block5_10_4:'Construction of Oil Collection Point (OCP) at "Sarybulak" deposit.',
          block5_10_5:'Customer "KazGerm-Service" LLP',
          block5_10_6:'Construction of CNG station at the address: Shymkent city,Kaikeldy Batyr street, building 19/1.',
          block5_10_7:'Customer "Sagiz Petroleum" LLP',
          block5_10_8:'Construction of Kopa delivery and acceptance point with trunk oil pipeline from CPPS to "Kopa" OPF and shift camp for 20 men in Baiganinsky region, Aktobe region.',
          block5_10_9:',Customer - "ProsperaResources" LLP',
          block5_10_10:',"Sagiz Oil Terminal" (NNT).',



          block5_10_12:"Customer China Petroleum Technology and Development Corporation",
          block5_10_13:"Feasibility study (FS) of the object Construction of a plant for the production of ETBE / MTBE and powdered polypropylene.",
          block5_10_14:"Customer LLP Knauf Gips Kapchagai",
          block5_10_15:"Gas supply of the plant for the production of gypsum plasterboards and dry building mixtures of Knauf Gips Kapchagai LLP.",
          block5_10_16:"Customer KAZPETROLEUM TECHNOLOGIES LLP",
          block5_10_17:"Design and survey work Equipping production facilities of Atyrau Oil Refinery LLP with control devices.",
          block5_10_18:"Customer CaspiOilGas LLP",
          block5_10_19:"Arrangement of the Rakushechnoye oil field during the exploration trial period. Oil preparation station at the Rakushechnoye oil field.",
          block5_10_20:"Client Alties Petroleum International B.V.",
          block5_10_21:"Arrangement of the Besbolek oil field for the period of exploration and production.",
          block5_10_22:"Construction of an oil pipeline from the Akzhar field to the tie-in point into the main oil pipeline of KazTransOil JSC.",
          block5_10_23:"Point of acceptance and transportation of oil Karsak",
          block5_10_24:"Technological regulations for the operation of the Karsak acceptance point.",
          block5_10_25:"Design of an overhead power transmission line with a voltage of 35 kilovolts from the existing Zharkamys substation to the projected Akzhar substation.",
          block5_10_26:"Customer LLP Buzachi Oil,",
          block5_10_27:"Development and implementation of a program for utilization of associated gas at the Karaturun Morskoy and Karaturun Vostochny gas and oil fields.",
          block5_10_28:"Customer JSC Kazkhimmontazh Holding (Agip KCO)",
          block5_10_29:"Development of the East Kashagan oil and gas field. Vertical steel tanks with a volume of 1500 m³; 1800 m³; 2200 m³ and 5800 m³. for storing water at the site of the engineering support of the construction camp.",
          block_5_10_31:"Customer LLP Kazakh Institute of Oil and Gas Transport",
          block_5_10_32:"At the request and instructions of the shareholders of KITNG LLP, in the period from December. 2007 to June 2011, a group of executives and chief specialists of MG Engineering implemented significant stages of work on project management Designing an effective organization for the implementation of a set of design works:",
          block_5_10_33:"“Construction of the main gas pipeline Kazakhstan - China.",
          block_5_10_34:"“Construction of the main oil pipeline Kenkiyak - Kumkol.",
          block_5_10_35:"Customer Big Sky Energy Corp.",
          block_5_10_36:"Arrangement of the Morskoye oil field.",
          block_5_10_37:"Customer Lancaster Petroleum (KKM Operating Company JSC)",
          block_5_10_38:"Collection and transportation of products from the wells of the Kokzhide-Nadsolevoye oil field.",
          block_5_10_39:"Expansion of the oil treatment shop at the Kokzhide-Nadsolevoye oil field.",
          block_5_10_40:"New rotational camp for service personnel.",
          block_5_10_41:"Development of normative and technical documentation “Technological losses of oil and gas during collection, transportation and preparation at industrial facilities of KKM Operating Company JSC",
          block_5_10_42:"Customer CNPC-Aktobemunaigas JSC",
          block_5_10_43:"Objects and structures of the automatic hermetically sealed oil loading and unloading system at the Bestamak railway station.",
          block_5_10_44:"Customer JSC Imstalkon",
          block_5_10_45:"Construction of vertical steel process tanks for the central oil treatment facility at the Karazhanbas oil and gas field.",
          block_5_10_46:"Reconstruction of the tank farm of the Tengiz intermediate pumping station.",
          block_5_10_47:"Tank farm for purified sour water and general maintenance at the Tengiz oil field.",
          block_5_10_49:"Customer JSC Karazhanbasmunai",
          block_5_10_50:"Installation of group metering installations.",
          block_5_10_51:"Construction of new technological facilities at the Karazhanbas gas and oil field.",
          block_5_10_52:"Expansion of the tank farm of the booster pumping station at the Karazhanbas gas and oil field.",
          block_5_10_53:"Expansion of the central oil treatment facility.",
          block_5_10_54:"Customer JSC Caspian Oil TME",
          block_5_10_55:"Collection and transportation of well products from the Alibek Yuzhny oil and gas condensate field.",
          block_5_10_56:"Expansion of the oil loading point at the Alibek Yuzhny field.",
          block_5_10_57:"Installation of water injection at the Alibek Yuzhny field.",
          block_5_10_58:"Intra-field roads at the Alibek South field.",
          block_5_10_59:"Reconstruction of substation SS-110/35/10 kV Kenkiyak and other structures and power supply devices.",
         
          block_5_10_60:"Customer JSC Condensate",
          block_5_10_61:"Repair and replacement of the raw gas pipeline section GP-3-Orenburg.",
          block_5_10_62:"Development of a conceptual design for a branded gas station, Uralsk.",
          block_5_10_63:"Customer JSC MontazhSpetsStroy",
          block_5_10_64:"Development of tender documents Expansion of the seawater intake installation",
          block_5_10_65:"Block-modular building of the temporary Office of Tengizchevroil LLP.",
          block_5_10_66:"Gas turbine power plant at the Dunga oil and gas field.",
          
          block_5_10_67:"Customer LLP BN Munai",
          block_5_10_68:"Development of the Kziloy gas field in the Aktobe region.",
          block_5_10_69:"Intra-field gas gathering pipelines.",
         
          block_5_10_70:"Customer LLP Dostyk Refinery",
          block_5_10_71:"Dostyk-2 railway export and transshipment oil depot of light oil products.",
          block_5_10_72:"Reconstruction of the Dostyk export-railway transshipment oil depot.",
          block_5_10_74:"Customer Prosystems Petroleum LLP",
          block_5_10_75:"Reconstruction of a service filling station, Almaty.",
          
          block_5_10_76:"Customer LLP Sinooil",
          block_5_10_77:"Reconstruction of the petroleum product base in Nur-Sultan.",
          block_5_10_78:"Service filling station, Almaty region, Karasai district, Kaskelen-Shamalgan highway.",
          block_5_10_79:"Service filling station, Almaty region, Enbekshikazakh district.",
          block_5_10_80:"Service gas station, Almaty region, Zhambyl region, Uzunagash village.",
          block_5_10_81:"Customer LLP Artis Overseas S.A. Kazakhstan",
          block_5_10_82:"Expansion of the oil terminal. Seaport of Aktau.",
          block_5_10_83:"Customer Zhaikmunai LLP",
          block_5_10_84:"Facilities and facilities for the development of the Chinarevskoye oil and gas condensate field.",
         
          block_5_10_85:"Customer Kazakhoil Aktobe LLP",
          block_5_10_86:"Development of the Kozhasai oil and gas condensate field for the period of trial operation.",
          block_5_10_87:"Point of reception and treatment of oil at the oil and gas condensate field Alibekmola",
          block_5_10_88:"A system for utilizing industrial wastewater by injecting it into the reservoir at the Alibekmola field.",
          block_5_10_89:"System for maintaining reservoir pressure in the northern part of the Alibekmola oil and gas condensate field.",
          block_5_10_90:"Development of technological regulations for the operation of an oil treatment facility at the Alibekmola field.",
          block_5_10_91:"Power supply system at the Kozhasai field.",
          block_5_10_92:"Overhead power lines with a voltage of six kilovolts with the South Alibekmola substation for power supply to the Central Oil and Gas Treatment Center of the Alibekmola field.",
         
          block_5_10_94:"Customer LLP Ken-Sary",
          block_5_10_95:"Arrangement of the Arystanovskoye oil field during exploration and trial operation. 1st, 2nd start-up complex.",
          block_5_10_96:"Arrangement of the Arystanovskoye field. III launch complex.",
          block_5_10_97:"Oil treatment station of the Arystanovskoye field.",
          
          block_5_10_98:"Customer LLP Kazakhstan-China Pipeline",
          block_5_10_99:"Construction of the Kazakhstan-China oil pipeline. Kenkiyak-Kumkol section. Joint emergency recovery point.",
          
          block_5_10_100:"Customer MegaTransAsia LLP",
          block_5_10_101:"Expansion of the production capacity of the tank farm. Shop for refining petroleum products at st. Beskol Alakol district of Almaty region.",
          block_5_10_102:"Oil refinery with a capacity of 1,100,000 tons / year.",
          block_5_10_103:"Oil refinery with a capacity of 600,000 tons / year.",
          
          block_5_10_104:"Customer NT Nurly Munai LLP",
          block_5_10_105:"Acceptance and delivery point of oil NT Nurly Munai.",
          
          block_5_10_106:"Customer Sagiz Petroleum Company LLP",
          block_5_10_107:"Assessment of the volume and cost of construction and installation works for the construction of the acceptance point Ebity. Blocking pipeline with a tie-in into the main oil pipeline Kenkiyak-Atyrau in the Bayganinsky district of the Aktobe region.",
          
          block_5_10_108:"Customer South Oil LLP",
          block_5_10_109:"Construction of a commercial oil metering unit and an oil pipeline at the Kenlyk oil field with a connection to the Kenkiyak-Kumkol oil trunk pipeline.",
          
          block_5_10_110:"Customer TEK Kazakhstan LLP",
          block_5_10_112:"Reconstruction of fuel depots for locomotive equipment at the railway stations Dostyk, Ushtobe, Kostanay.",
          
          block_5_10_113:"Customer LLP Umay-Market",
          block_5_10_114:"A distribution railway terminal for light oil products in the city of Arys;",
          block_5_10_115:"Technical support and construction management.",
          
          block_5_10_116:"Customer LLP Ernst & Young Consulting Services",
          
          block_5_10_117:"Development of a feasibility study for the construction and operation of 95 CNG filling stations in the Republic of Kazakhstan.",
          

          act0:'Activity',
          act:'"The company activity is aimed at superior implementation of engineering and design package, construction of new facilities, reconstruction and upgrade of oil and gas industrial facilities and structures already in operation.The company is open for cooperation and is ready to accept any mutually beneficial offers in efficient performance of oil and gas production, treatment and transportation projects both inside and outside Kazakhstan."',
          act1:"INTEGRATED DESIGN:",
          act1_1:"development of oil-gas-condensate fields:",
          act1_2:"arrangement of wells;",
          act1_3:"systems for field gathering of well products;",
          act1_4:"metering installations;",
          act1_5:"installations for the primary discharge of produced water;",
          act1_6:"reservoir pressure maintenance systems;",
          act1_7:"cluster pumping and compressor stations.",
          act1_8:"oil and gas treatment facilities up to marketable condition:",
          act1_9:"oil and gas treatment plants;",
          act1_10:"installations for receiving and accounting for incoming products;",
          act1_11:"separation plants, including degassing,",
          act1_12:"dehydration, desalting and demercaptanization of oil;",
          act1_13:"gas purification and drying units;",
          act1_14:"gas pretreatment units (UPPG);",
          act1_15:"Complex gas treatment units (UKPG);",
          act1_16:"installations for the preparation of solutions and components accompanying the purification of oil and gas;",
          act1_17:"formation water treatment plants;",
          act1_18:"technological pumping and compressor stations;",
          act1_19:"tank farms for the collection of finished products;",
          act1_20:"pumping and compressor stations for pumping finished products.",
          act1_20:"oil and gas transportation facilities and structures:",
          act1_21:"main pipelines for any purpose;",
          act1_23:"field, technological, distribution pipelines of oil and gas.",
          act1_23:"oil, gas and hydrocarbon processing facilities;",
          act1_24:"facilities for storage, distribution and sale of petroleum products;",
          act1_25:"facilities for storage, distribution and sale of gas;",
          act1_26:"other facilities included in the infrastructure of oil production, pipeline transport, industrial and civil structures;",
          act1_27:"fire and electrochemical protection systems;",
          act1_28:"TECHNICAL AND ECONOMIC SURVEYS:",
          act1_29:"technical and economic calculations;",
          act1_30:"feasibility studies of investments.",
          act1_31:"TECHNICAL SURVEYS:",
          act1_32:"existing objects of the oil and gas complex with an assessment of their impact on the environment;",
          act1_34:"development of recommendations for the repair, reconstruction and modernization of the surveyed objects of the oil and gas complex.",
          act1_35:"TECHNICAL AND AUTHOR'S SUPERVISION OF CONSTRUCTION",
          act1_36:"DEVELOPMENT OF REGULATORY, REVIEW AND ANALYTICAL DOCUMENTATION",
          act1_37:"ENGINEERING SERVICES:",
          act1_38:"preparation of documentation and holding tenders;",
          act1_39:"receipt and execution of initial data;",
          act1_40:"preparation of technical specifications;",
          act1_41:"preparation of documentation and implementation of approvals;",
          act1_42:"supply of equipment for the oil and gas industry;",
          act1_43:"construction management, turnkey implementation of individual objects of the oil and gas complex, industrial and civil construction.",
          
          cert:"CERTIFICATES AND LICENSES",

          cert0:"CERTIFICATES",

          cert1:'Certificate ST RK OHSAS 18001: 2008 "Occupational Safety and Health Management System" issued by ISOKZ Certification Center LLP.',

          cert2:'Certificate ST RK ISO 14001: 2015 "Environmental Management System" issued by "Metrology & Certification" LLP.',

          cert3:'Certificate ST RK ISO 9001-2016 (ISO 14001: 2015) "Quality Management System" issued by "Metrology & Certification" LLP."',
          
          lic0:'LICENSES',

          lic1:'State license 14016989 Ⅰ (first) category (general) dated November 10, 2014 for design, issued by the Committee for Construction, Housing and Communal Services and Land Management. Ministry of National Economy of the Republic of Kazakhstan.',

          lic2:'State license 14001279 (general) dated 02/04/2014 for design (technological) and (or) operation of mining (exploration, mining), petrochemical, chemical production, design (technological) oil and gas processing industries, operation of gas pipelines, oil pipelines, oil product pipelines issued by the Ministry of Oil and Gas of the Republic of Kazakhstan. State Inspection Committee in the Oil and Gas Complex of the Ministry of Oil and Gas of the Republic of Kazakhstan.',

          lic3:'License 01625R (general) dated January 23, 2014 for the performance of work and provision of services in the field of environmental protection, issued by the Ministry of Environment and Water Resources of the Republic of Kazakhstan. Committee for Environmental Regulation and Control of the Ministry of Environment and Water Resources of the Republic of Kazakhstan.',

          lic4:' State license 21018179 20 dated 05.19.2021 for exploration activities.',
         
          lic4_0:'Engineering-geological and engineering-hydrogeological works, including:',
          lic4_1:'field studies of soils, hydrogeological studies;',
          lic4_2:'geophysical research, reconnaissance and shooting.',
          lic4_3:'Engineering and geodetic works, including:',
          lic4_4:'topographic works for design and construction (surveying on a scale from 1: 10000 to 1: 200, as well as surveying underground utilities and structures, tracing and surveying ground linear structures and their elements);',
          lic4_5:'geodetic work related to the transfer to nature with geotechnical engineering reference',
          lic4_6:'workings, geophysical and other survey points;',
          lic4_7:'construction and laying of geodetic centers;',
          lic4_8:'creation of planned high-altitude survey networks."',
          
        
         
         
         
          cont2:'"The Republic of Kazakhstan" Almaty, 050052 md. Taugul-3,st. Zhanturina, 23',
          cont2:'tel.: +7 (727) 239 77 21, +7 (727) 239 12 39, +7 701 318 48 70, +7 701 971 91 99',
          cont6:'e-mail: info@mgeng.kz',

          map:'Map:',











        }
      }
    },
    fallbackLng: "ru",
    // debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
